import { Button, Card, Form, Input } from "antd-mobile";
import { useState } from "react";
import { useStores } from "../../store/RootStoreContext";
import { IRegData } from "../../store/userStore";
import { Navigate } from "react-router-dom";
import { Paths } from "../Navigation";
import { observer } from "mobx-react-lite";

interface RegFlds extends IRegData {
  repeatPassword: string;
}
const SignUp = observer(() => {
  const { userStore } = useStores();
  const { isAuth, register } = userStore;
  const [pass, setPass] = useState<string>();
  const onFinish = async (v: RegFlds) => {
    await register({
      email: v.email,
      password: v.password,
      username: v.username,
    });
  };
  if (isAuth) return <Navigate to={`/${Paths.profile}`} replace />;
  const checkPass = (_: any, value) => {
    if (value == pass) return Promise.resolve();
    return Promise.reject(new Error("Пароли не совпадают"));
  };
  return (
    <Card style={{ margin: 12 }}>
      <Form
        onFinish={onFinish}
        footer={
          <Button block type="submit" color="primary" size="large">
            Зарегистрироваться
          </Button>
        }
      >
        <Form.Header>Регистрация</Form.Header>
        <Form.Item
          rules={[{ required: true, message: "Заполните имя E-mail" }]}
          name="email"
          label="E-mail"
        >
          <Input type="email" name="email" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Заполните имя пользователя" }]}
          name="username"
          label="Имя пользователя"
        >
          <Input name="username" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Заполните пароль" }]}
          name="password"
          label="Пароль"
        >
          <Input
            type="password"
            onChange={(v) => {
              setPass(v);
            }}
            name="password"
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              validator: checkPass,
              message: "Пароли не совпадают",
            },
          ]}
          name="repeatPassword"
          label="Повторите пароль"
        >
          <Input type="password" name="repeatPassword" />
        </Form.Item>
      </Form>
    </Card>
  );
});

export default SignUp;
