import "./auth.css";
import { observer } from "mobx-react-lite";
import { ILoginData } from "../../store/userStore";
import { useStores } from "../../store/RootStoreContext";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { Button, Form, FormItemProps, Input } from "antd-mobile";
import { Paths } from "../Navigation";

const Login = observer(() => {
  const {
    userStore: { login, isAuth, loading },
  } = useStores();

  const checkUserCredentials = (event: React.FormEvent) => {
    event.preventDefault();
    if (!loginData.identifier) {
      setMailHelp({
        help: "Введите email",
        hasFeedback: true,
      });
    } else if (mailHelp.hasFeedback) setMailHelp({});
    if (!loginData.password) {
      setPassHelp({
        help: "Введите пароль",
        hasFeedback: true,
      });
    } else if (passHelp.hasFeedback) setPassHelp({});

    if (mailHelp.hasFeedback || passHelp.hasFeedback) return;
    login(loginData).catch(() => {
      setPassHelp({
        help: "Введите правильный пароль",
        hasFeedback: true,
      });
    });
  };
  const [mailHelp, setMailHelp] = useState<FormItemProps>({});
  const [passHelp, setPassHelp] = useState<FormItemProps>({});
  const [register, setRegister] = useState(false);
  const [loginData, setLoginData] = useState<ILoginData>({
    password: "",
    identifier: "",
  });
  if (isAuth) return <Navigate to={`/${Paths.profile}`} replace />;
  if (register) return <Navigate to={`/${Paths.register}`} replace />;
  return (
    <div className="loginForm">
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
      </div>
      <Form layout="vertical">
        <h3 className="authControl">Авторизация</h3>
        <Form.Item required label="E-mail" {...mailHelp}>
          <Input
            type="email"
            placeholder="Введите e-mail"
            value={loginData.identifier}
            onChange={(identifier) => {
              setLoginData({ ...loginData, identifier });
            }}
          />
        </Form.Item>
        <Form.Item required label="Пароль" {...passHelp}>
          <Input
            type="password"
            value={loginData.password}
            onChange={(password) => {
              setLoginData({ ...loginData, password });
            }}
            placeholder="Введите пароль"
          />
        </Form.Item>
        <Button
          onClick={checkUserCredentials}
          style={{ width: "100%", marginTop: 10 }}
          loading={loading}
        >
          Войти
        </Button>
        <Button
          onClick={() => {
            setRegister(true);
          }}
          style={{ width: "100%", marginTop: 10 }}
          loading={loading}
        >
          Зарегистрироваться
        </Button>
      </Form>
    </div>
  );
});
export default Login;
