import { ImageUploadItem } from "antd-mobile";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import { TUserStore } from "./userStore";

interface IResult {
  txt: string;
  status: boolean;
}
class FeedbackStore {
  formData: FormData = new FormData();
  userStore: TUserStore;
  result: IResult;
  constructor(userStore: TUserStore) {
    this.userStore = userStore;
    makeAutoObservable(this);
  }
  setResult = (res: IResult) => {
    this.result = res;
  };
  clearResult = () => {
    this.result = null;
  };

  submit = async (values: { description: string }) => {
    const data = {
      description: values.description,
      tgName:
        this.userStore?.user?.username ??
        this.userStore.userTG?.username ??
        "Пользователь WEB",
    };
    this.formData.append("data", JSON.stringify(data));

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/feedbacks`,
        this.formData,
      );
    } catch (e) {
      this.setResult({
        status: false,
        txt: "Не удалось отправить отзыв",
      });
    } finally {
      this.formData = new FormData();
      this.setResult({
        status: true,
        txt: "Отзыв успешно отправлен",
      });
    }
  };

  uploadFeedbackImg = (file: File): Promise<ImageUploadItem> => {
    const url = URL.createObjectURL(file);
    this.formData.append("files.photos", file);

    const res: ImageUploadItem = {
      key: url,
      url,
    };
    return new Promise((r) => r(res));
  };
}

const feedbackStore = FeedbackStore;
export type TFeedBackStore = FeedbackStore;
export { feedbackStore };
